import { Stack, Typography } from '@mui/material';
import ColorPalette from 'utils/ColorPalette';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import IconFooter from 'assets/icons/IconFooter';

function SideBarFooter() {
  return (
    <Stack className="align-items-center mt-auto">
      <Stack
        direction="row"
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          paddingBlock: '.5rem',
          borderBottom: `1px solid ${ColorPalette.primary}`,
          borderTop: `1px solid ${ColorPalette.primary}`,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ marginRight: 'auto' }}
        >
          <IconFooter svgColor={ColorPalette.primary} sx={{ width: 25 }} />
          <Typography
            color={ColorPalette.textPrimary}
            sx={{
              fontSize: '1.4rem',
              marginLeft: '7px',
            }}
          >
            sTalk
          </Typography>
        </Stack>
        <LinkedInIcon sx={{ marginRight: '10px', color: ColorPalette.primary }} />
        <InstagramIcon sx={{ marginRight: '10px', color: ColorPalette.primary }} />
      </Stack>
      <Typography
        sx={{
          maxWidth: '100%',
          marginInlineStart: '0px',
          marginBlockStart: '1rem',
          marginBottom: '1rem',
          textAlign: 'center',
          fontSize: '.7rem',
        }}
        color={ColorPalette.offBlack}
      >
        © 2025 Harbinger Technovation Ltd. All rights reserved.
      </Typography>
    </Stack>
  );
}

export default SideBarFooter;
